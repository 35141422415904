.modal {
          display: none; /* Hidden by default */
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
        }
        
        .modal-content {
          background-color: white;
          margin: 3% auto;
          padding: 10px;
          border: 1px solid #888;
          width: 80%;
          height: 600px;

          max-width: 650px;
          
        }



    /* Add Animation */
    @-webkit-keyframes animatetop {
        from {top:-300px; opacity:0} 
        to {top:0; opacity:1}
    }
    
    @keyframes animatetop {
        from {top:-300px; opacity:0}
        to {top:0; opacity:1}
    }
    
    /* The Close Button */
    .close {
        color: black;
        float: right;
        font-size: 28px;
        font-weight: bold;
    }
    
    .close:hover,
    .close:focus {
        color: orangered;
        
        cursor: pointer;
    }
    
    .modal-header {
        padding: 2px 16px;
        background-color: #696CFF29;
        color: white;
    }
    
    .modal-body {
      padding: 2px 16px;
      overflow: scroll;
    }
    .modal-body::-webkit-scrollbar {
      display: none;
    }